/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledButton = styled(ButtonDefault)`
  font-weight: ${props => props.theme.font.weight.xl};

  @media screen and (max-width: 576px) {
    a {
      font-size: ${props => props.theme.font.size.m};
    }
  }
`

const HeroDefault = ({ fields }) => (
  <Hero src={fields.image} style={{ backgroundPosition: 'bottom' }} aboveFold>
    <div className="container py-lg-5 py-4">
      <div className="col-lg-6 pt-lg-5">
        <Content className="mb-5" content={fields.description} />
        <StyledButton to="/contact">Neem contact met ons op</StyledButton>
      </div>
    </div>
  </Hero>
)

export default HeroDefault

const Hero = styled(ImageBackground)`
  &:before {
    background-position: bottom !important;
    background-size: cover !important;
  }
  min-height: 800px;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.85);

  strong {
    margin-bottom: 35px;
    display: flex;
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.xxxl};
    line-height: ${(props) => props.theme.font.size.xxxl};
    font-family: ${(props) => props.theme.font.family.secondary};
    color: white;
    position: relative;
    text-transform: uppercase;
    padding-bottom: 40px;
    margin-bottom: 50px;

    @media screen and (max-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl};
      line-height: ${(props) => props.theme.font.size.xxl};
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 100px;
      width: 2000px;
      height: 5px;
      background-color: ${props => props.theme.color.face.main};

      @media screen and (max-width: 576px) {
        right: 10%;
      }
    }
  }

  p {
    font-weight: ${props => props.theme.font.weight.sm};
  }
`