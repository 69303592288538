/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  h4 {
    font-weight: ${props => props.theme.font.weight.m};
    font-size: ${props => props.theme.font.size.l};
    text-transform: uppercase;
    font-family: ${props => props.theme.font.family.secondary};
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroDefault fields={acf.banner} />

      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Content content={acf.story.description} />
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }
        story {
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
